import React, { useState } from 'react';
import { Send, AlertCircle, Building2, Globe, Hash, MessageSquare, CheckCircle } from 'lucide-react';
import toast from 'react-hot-toast';
import { Client, SubmissionData } from '../types';

interface SubmissionPortalProps {
  clients: Client[];
  onSubmitRequest: (clientId: string, data: SubmissionData) => Promise<void>;
}

const formatPhoneNumber = (value: string) => {
  const numbers = value.replace(/\D/g, '');
  if (numbers.length === 0) return '';
  if (numbers.length <= 3) return `(${numbers}`;
  if (numbers.length <= 6) return `(${numbers.slice(0, 3)}) ${numbers.slice(3)}`;
  return `(${numbers.slice(0, 3)}) ${numbers.slice(3, 6)}-${numbers.slice(6, 10)}`;
};

export function SubmissionPortal({ clients, onSubmitRequest }: SubmissionPortalProps) {
  const [phone, setPhone] = useState('');
  const [client, setClient] = useState<Client | null>(null);
  const [isVerifying, setIsVerifying] = useState(true);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [formData, setFormData] = useState({
    dealershipName: '',
    dealershipWebsite: '',
    stockNumbers: '',
    adDescription: ''
  });

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formatted = formatPhoneNumber(e.target.value);
    if (formatted.length <= 14) {
      setPhone(formatted);
    }
  };

  const handleVerifyClient = (e: React.FormEvent) => {
    e.preventDefault();
    const foundClient = clients.find(c => c.phone === phone);
    
    if (foundClient) {
      if (foundClient.credits <= 0) {
        window.location.href = 'https://www.paypal.com/ncp/payment/YXDUTPHT5BUW6';
        return;
      }
      setClient(foundClient);
      setIsVerifying(false);
      toast.success('Client verified successfully!');
    } else {
      toast.error('Client not found. Please check your phone number.');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!client) return;

    try {
      if (!formData.dealershipName.trim() || !formData.stockNumbers.trim() || !formData.adDescription.trim()) {
        toast.error('Please fill in all required fields');
        return;
      }

      await onSubmitRequest(client.id, {
        ...formData,
        clientName: client.name,
        clientPhone: client.phone
      });
      
      setShowSuccessModal(true);
      setFormData({
        dealershipName: '',
        dealershipWebsite: '',
        stockNumbers: '',
        adDescription: ''
      });
    } catch (error) {
      console.error('Error submitting request:', error);
      toast.error('Failed to submit request. Please try again.');
    }
  };

  const handleSubmitAnother = () => {
    setShowSuccessModal(false);
    setIsVerifying(true);
    setPhone('');
    setClient(null);
  };

  if (showSuccessModal) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
        <div className="bg-white rounded-lg p-6 max-w-md w-full text-center">
          <CheckCircle className="w-16 h-16 text-green-500 mx-auto mb-4" />
          <h2 className="text-2xl font-bold text-gray-900 mb-2">Request Received!</h2>
          <p className="text-gray-600 mb-6">
            We received your video request. Please allow us up to 48 hours to create your request.
          </p>
          <button
            onClick={handleSubmitAnother}
            className="w-full bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition-colors"
          >
            Submit Another Request
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4">
      <div className="max-w-lg mx-auto">
        <div className="bg-white rounded-lg shadow-md border border-gray-200 p-6">
          <div className="text-center mb-8">
            <h1 className="text-2xl font-bold text-gray-900 mb-2">Video Request Portal</h1>
            <p className="text-gray-600">Submit your request using your available credits</p>
          </div>

          {isVerifying ? (
            <form onSubmit={handleVerifyClient} className="space-y-4">
              <div>
                <label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-1">
                  Phone Number
                </label>
                <input
                  type="tel"
                  id="phone"
                  value={phone}
                  onChange={handlePhoneChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  required
                  placeholder="(555) 555-5555"
                />
              </div>

              <button
                type="submit"
                className="w-full bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 transition-colors"
              >
                Enter
              </button>
            </form>
          ) : (
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="bg-indigo-50 border border-indigo-100 rounded-md p-4 mb-6">
                <div className="flex items-center justify-between mb-1">
                  <span className="text-sm font-medium text-gray-700">Welcome back,</span>
                  <span className="text-sm text-indigo-600 font-semibold">
                    {client?.credits} credits remaining
                  </span>
                </div>
                <p className="text-lg font-medium text-gray-900">{client?.name}</p>
                <p className="text-sm text-gray-500">{client?.phone}</p>
              </div>

              <div>
                <label htmlFor="dealershipName" className="block text-sm font-medium text-gray-700 mb-1">
                  <div className="flex items-center gap-2">
                    <Building2 className="w-4 h-4" />
                    Dealership Name
                  </div>
                </label>
                <input
                  type="text"
                  id="dealershipName"
                  value={formData.dealershipName}
                  onChange={(e) => setFormData(prev => ({ ...prev, dealershipName: e.target.value }))}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  required
                />
              </div>

              <div>
                <label htmlFor="dealershipWebsite" className="block text-sm font-medium text-gray-700 mb-1">
                  <div className="flex items-center gap-2">
                    <Globe className="w-4 h-4" />
                    Dealership Website
                  </div>
                </label>
                <input
                  type="text"
                  id="dealershipWebsite"
                  value={formData.dealershipWebsite}
                  onChange={(e) => setFormData(prev => ({ ...prev, dealershipWebsite: e.target.value }))}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
              </div>

              <div>
                <label htmlFor="stockNumbers" className="block text-sm font-medium text-gray-700 mb-1">
                  <div className="flex items-center gap-2">
                    <Hash className="w-4 h-4" />
                    Stock Numbers
                  </div>
                </label>
                <input
                  type="text"
                  id="stockNumbers"
                  value={formData.stockNumbers}
                  onChange={(e) => setFormData(prev => ({ ...prev, stockNumbers: e.target.value }))}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  required
                />
              </div>

              <div>
                <label htmlFor="adDescription" className="block text-sm font-medium text-gray-700 mb-1">
                  <div className="flex items-center gap-2">
                    <MessageSquare className="w-4 h-4" />
                    What would you like this ad to be about?
                  </div>
                </label>
                <textarea
                  id="adDescription"
                  value={formData.adDescription}
                  onChange={(e) => setFormData(prev => ({ ...prev, adDescription: e.target.value }))}
                  rows={4}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  required
                />
              </div>

              <div className="flex items-center gap-2 text-sm text-amber-600 bg-amber-50 p-3 rounded-md">
                <AlertCircle className="w-4 h-4" />
                <p>Submitting this request will use 1 credit from your account.</p>
              </div>

              <div className="flex gap-3">
                <button
                  type="submit"
                  className="flex-1 bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 transition-colors flex items-center justify-center gap-2"
                >
                  <Send className="w-4 h-4" />
                  Submit Ad Request
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setIsVerifying(true);
                    setPhone('');
                    setFormData({
                      dealershipName: '',
                      dealershipWebsite: '',
                      stockNumbers: '',
                      adDescription: ''
                    });
                    setClient(null);
                  }}
                  className="flex-1 bg-gray-200 text-gray-800 px-4 py-2 rounded-md hover:bg-gray-300 transition-colors"
                >
                  Cancel
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}