import React, { useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { ClientList } from './components/ClientList';
import { ClientForm } from './components/ClientForm';
import { Header } from './components/Header';
import { SubmissionPortal } from './components/SubmissionPortal';
import { AdminAuth } from './components/AdminAuth';
import { useClients, useSubmissions } from './hooks/useFirestore';
import * as db from './lib/db';
import toast from 'react-hot-toast';

// In a real app, this would be stored securely on the server
const ADMIN_PASSWORD = 'elite27';

function App() {
  const { clients, loading: clientsLoading } = useClients();
  const { submissions, loading: submissionsLoading } = useSubmissions();
  const [isAdminView, setIsAdminView] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [selectedSubmission, setSelectedSubmission] = useState<any>(null);

  const handleAddClient = async (client: { name: string; phone: string; credits: number }) => {
    try {
      await db.addClient(client);
      toast.success('Client added successfully');
    } catch (error) {
      toast.error('Failed to add client');
      console.error(error);
    }
  };

  const handleUpdateCredits = async (clientId: string, credits: number) => {
    try {
      await db.updateClientCredits(clientId, credits);
      toast.success('Credits updated successfully');
    } catch (error) {
      toast.error('Failed to update credits');
      console.error(error);
    }
  };

  const handleDeleteClient = async (clientId: string) => {
    try {
      await db.deleteClient(clientId);
      toast.success('Client deleted successfully');
    } catch (error) {
      toast.error('Failed to delete client');
      console.error(error);
    }
  };

  const handleDeleteSubmission = async (submissionId: string) => {
    try {
      await db.deleteSubmission(submissionId);
      toast.success('Submission deleted successfully');
      setSelectedSubmission(null);
    } catch (error) {
      toast.error('Failed to delete submission');
      console.error(error);
    }
  };

  const handleAdminAuth = (password: string) => {
    if (password === ADMIN_PASSWORD) {
      setIsAdminView(true);
      setShowAuthModal(false);
      toast.success('Admin access granted');
    } else {
      toast.error('Invalid admin password');
    }
  };

  if (clientsLoading || submissionsLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <Toaster position="top-right" />
      <Header />
      
      <div className="max-w-7xl mx-auto py-8 px-4">
        <div className="mb-8 flex justify-center">
          <div className="inline-flex rounded-lg border border-gray-200 overflow-hidden">
            <button
              onClick={() => {
                setIsAdminView(false);
                setShowAuthModal(false);
              }}
              className={`px-4 py-2 text-sm font-medium ${
                !isAdminView
                  ? 'bg-indigo-600 text-white'
                  : 'bg-white text-gray-700 hover:bg-gray-50'
              }`}
            >
              Client Portal
            </button>
            <button
              onClick={() => !isAdminView && setShowAuthModal(true)}
              className={`px-4 py-2 text-sm font-medium ${
                isAdminView
                  ? 'bg-indigo-600 text-white'
                  : 'bg-white text-gray-700 hover:bg-gray-50'
              }`}
            >
              Admin Dashboard
            </button>
          </div>
        </div>

        {isAdminView ? (
          <div className="space-y-8">
            <div className="grid gap-8 md:grid-cols-[350px,1fr]">
              <ClientForm onSubmit={handleAddClient} />
              <ClientList 
                clients={clients}
                submissions={submissions}
                onUpdateCredits={handleUpdateCredits}
                onDeleteClient={handleDeleteClient}
                onDeleteSubmission={handleDeleteSubmission}
                onSubmissionClick={setSelectedSubmission}
                selectedSubmission={selectedSubmission}
              />
            </div>
          </div>
        ) : (
          <SubmissionPortal 
            clients={clients}
            onSubmitRequest={db.addSubmission}
          />
        )}

        <AdminAuth 
          isOpen={showAuthModal}
          onClose={() => setShowAuthModal(false)}
          onSubmit={handleAdminAuth}
        />
      </div>
    </div>
  );
}

export default App;