import React, { useState } from 'react';
import { Trash2, Plus, Minus, Search, TrendingDown, Clock, MessageSquare, X, Building2, Globe, Hash } from 'lucide-react';
import toast from 'react-hot-toast';
import { Client, Submission } from '../types';

interface ClientListProps {
  clients: Client[];
  submissions: Submission[];
  onUpdateCredits: (clientId: string, credits: number) => void;
  onDeleteClient: (clientId: string) => void;
  onDeleteSubmission: (submissionId: string) => void;
}

interface SubmissionModalProps {
  submission: Submission;
  onClose: () => void;
  onDelete: (id: string) => void;
}

interface PendingRequestsModalProps {
  submissions: Submission[];
  onClose: () => void;
  onDelete: (id: string) => void;
  onViewDetails: (submission: Submission) => void;
}

function PendingRequestsModal({ submissions, onClose, onDelete, onViewDetails }: PendingRequestsModalProps) {
  const formatDate = (date: Date | null) => {
    if (!date) return 'N/A';
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    }).format(date);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg p-6 max-w-4xl w-full max-h-[80vh] overflow-y-auto">
        <div className="flex justify-between items-start mb-6">
          <h3 className="text-xl font-semibold text-gray-900">Pending Requests</h3>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="space-y-4">
          {submissions.map(submission => (
            <div 
              key={submission.id}
              className="bg-white p-4 rounded-lg border border-orange-200 hover:bg-orange-50 transition-colors cursor-pointer"
              onClick={() => onViewDetails(submission)}
            >
              <div className="flex justify-between items-start mb-2">
                <div>
                  <h4 className="font-medium text-gray-900">{submission.dealershipName}</h4>
                  <p className="text-sm text-gray-500">Client: {submission.clientName}</p>
                  <p className="text-sm text-gray-500">Phone: {submission.clientPhone}</p>
                </div>
                <span className="text-sm text-gray-500">{formatDate(submission.timestamp)}</span>
              </div>
              <p className="text-sm text-gray-600 mb-2">Stock #: {submission.stockNumbers}</p>
              <p className="text-sm text-gray-600 line-clamp-2">{submission.adDescription}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function SubmissionModal({ submission, onClose, onDelete }: SubmissionModalProps) {
  const formatDate = (date: Date | null) => {
    if (!date) return 'N/A';
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    }).format(date);
  };

  const handleDelete = () => {
    onDelete(submission.id);
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg p-6 max-w-2xl w-full">
        <div className="flex justify-between items-start mb-6">
          <h3 className="text-xl font-semibold text-gray-900">Submission Details</h3>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="space-y-6">
          <div className="grid grid-cols-2 gap-6">
            <div>
              <h4 className="flex items-center gap-2 text-sm font-medium text-gray-500 mb-1">
                <Building2 className="w-4 h-4" />
                Dealership
              </h4>
              <p className="text-gray-900">{submission.dealershipName}</p>
            </div>

            <div>
              <h4 className="flex items-center gap-2 text-sm font-medium text-gray-500 mb-1">
                <Globe className="w-4 h-4" />
                Website
              </h4>
              <p className="text-gray-900">{submission.dealershipWebsite || 'Not provided'}</p>
            </div>

            <div>
              <h4 className="flex items-center gap-2 text-sm font-medium text-gray-500 mb-1">
                <Hash className="w-4 h-4" />
                Stock Numbers
              </h4>
              <p className="text-gray-900">{submission.stockNumbers}</p>
            </div>

            <div>
              <h4 className="flex items-center gap-2 text-sm font-medium text-gray-500 mb-1">
                <Clock className="w-4 h-4" />
                Submitted
              </h4>
              <p className="text-gray-900">{formatDate(submission.timestamp)}</p>
            </div>
          </div>

          <div>
            <h4 className="flex items-center gap-2 text-sm font-medium text-gray-500 mb-1">
              <MessageSquare className="w-4 h-4" />
              Ad Description
            </h4>
            <p className="text-gray-900 whitespace-pre-wrap">{submission.adDescription}</p>
          </div>

          <div className="border-t pt-6">
            <div className="flex justify-between items-center">
              <div>
                <h4 className="text-sm font-medium text-gray-500">Client Information</h4>
                <p className="text-gray-900">{submission.clientName}</p>
                <p className="text-gray-600">{submission.clientPhone}</p>
              </div>
              <button
                onClick={handleDelete}
                className="bg-red-50 text-red-600 px-4 py-2 rounded-md hover:bg-red-100 transition-colors flex items-center gap-2"
              >
                <Trash2 className="w-4 h-4" />
                Delete Submission
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function ClientList({ clients, submissions, onUpdateCredits, onDeleteClient, onDeleteSubmission }: ClientListProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedClient, setSelectedClient] = useState<string | null>(null);
  const [creditAmount, setCreditAmount] = useState(0);
  const [selectedSubmission, setSelectedSubmission] = useState<Submission | null>(null);
  const [showPendingRequests, setShowPendingRequests] = useState(false);

  const filteredClients = clients.filter(client =>
    client.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    client.phone.includes(searchTerm)
  );

  const handleCreditUpdate = (clientId: string, action: 'add' | 'remove') => {
    const client = clients.find(c => c.id === clientId);
    if (!client) return;

    if (creditAmount <= 0) {
      toast.error('Please enter a valid credit amount');
      return;
    }

    const newCredits = action === 'add' 
      ? client.credits + creditAmount
      : Math.max(0, client.credits - creditAmount);

    onUpdateCredits(clientId, newCredits);
    toast.success(`Credits ${action === 'add' ? 'added to' : 'removed from'} ${client.name}'s account`);
    setSelectedClient(null);
    setCreditAmount(0);
  };

  const formatDate = (date: Date | null) => {
    if (!date) return 'Never';
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    }).format(date);
  };

  const getClientSubmissions = (clientId: string) => {
    return submissions.filter(sub => sub.clientId === clientId);
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-lg border border-gray-200">
      <div className="mb-6">
        <h2 className="text-lg font-semibold text-gray-900 mb-2">Client List</h2>
        <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
          <div className="bg-indigo-50 p-4 rounded-lg">
            <p className="text-sm text-indigo-600 font-medium">Total Clients</p>
            <p className="text-2xl font-bold text-indigo-900">{clients.length}</p>
          </div>
          <div className="bg-emerald-50 p-4 rounded-lg">
            <p className="text-sm text-emerald-600 font-medium">Total Credits</p>
            <p className="text-2xl font-bold text-emerald-900">
              {clients.reduce((sum, client) => sum + client.credits, 0)}
            </p>
          </div>
          <div 
            className="bg-amber-50 p-4 rounded-lg sm:col-span-1 col-span-2 cursor-pointer hover:bg-amber-100 transition-colors"
            onClick={() => setShowPendingRequests(true)}
          >
            <p className="text-sm text-amber-600 font-medium">Pending Requests</p>
            <p className="text-2xl font-bold text-amber-900">
              {submissions.length}
            </p>
          </div>
        </div>
      </div>

      <div className="mb-4 relative">
        <input
          type="text"
          placeholder="Search clients..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
        />
        <Search className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
      </div>

      <div className="space-y-6">
        {filteredClients.length === 0 ? (
          <p className="text-center text-gray-500 py-4">No clients found</p>
        ) : (
          filteredClients.map(client => {
            const clientSubmissions = getClientSubmissions(client.id);
            const hasPendingSubmissions = clientSubmissions.length > 0;

            return (
              <div 
                key={client.id} 
                className={`border rounded-lg ${
                  hasPendingSubmissions 
                    ? 'border-orange-300 bg-orange-50' 
                    : 'border-gray-200'
                }`}
              >
                <div className="p-4">
                  <div className="flex justify-between items-start mb-4">
                    <div>
                      <h3 className="font-medium text-gray-900">{client.name}</h3>
                      <p className="text-sm text-gray-500">{client.phone}</p>
                    </div>
                    <button
                      onClick={() => onDeleteClient(client.id)}
                      className="text-red-600 hover:text-red-700 p-1"
                    >
                      <Trash2 className="w-4 h-4" />
                    </button>
                  </div>

                  <div className="bg-white p-3 rounded-md mb-4 border border-gray-100">
                    <div className="flex items-center justify-between">
                      <div>
                        <p className="text-sm font-medium text-gray-600">Available Credits</p>
                        <p className={`text-lg font-bold ${
                          client.credits > 5 
                            ? 'text-emerald-600' 
                            : client.credits > 0 
                              ? 'text-amber-600' 
                              : 'text-red-600'
                        }`}>
                          {client.credits}
                        </p>
                      </div>
                      {client.credits <= 5 && (
                        <div className="flex items-center gap-1 text-amber-600 bg-amber-50 px-2 py-1 rounded">
                          <TrendingDown className="w-4 h-4" />
                          <span className="text-sm font-medium">Low Credits</span>
                        </div>
                      )}
                    </div>
                  </div>

                  {client.lastSubmission && (
                    <div className="flex items-center gap-2 text-sm text-gray-500 mb-4">
                      <Clock className="w-4 h-4" />
                      <span>Last submission: {formatDate(client.lastSubmission)}</span>
                    </div>
                  )}

                  <div className="flex items-center justify-between">
                    {selectedClient === client.id ? (
                      <div className="flex items-center gap-2">
                        <input
                          type="number"
                          min="0"
                          value={creditAmount}
                          onChange={(e) => setCreditAmount(parseInt(e.target.value) || 0)}
                          className="w-20 px-2 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-indigo-500"
                        />
                        <button
                          onClick={() => handleCreditUpdate(client.id, 'add')}
                          className="p-1 text-green-600 hover:text-green-700"
                        >
                          <Plus className="w-4 h-4" />
                        </button>
                        <button
                          onClick={() => handleCreditUpdate(client.id, 'remove')}
                          className="p-1 text-red-600 hover:text-red-700"
                        >
                          <Minus className="w-4 h-4" />
                        </button>
                      </div>
                    ) : (
                      <button
                        onClick={() => setSelectedClient(client.id)}
                        className="text-sm text-indigo-600 hover:text-indigo-700 font-medium"
                      >
                        Manage Credits
                      </button>
                    )}
                  </div>
                </div>

                {clientSubmissions.length > 0 && (
                  <div className="border-t border-orange-200">
                    <div className="p-4 space-y-4">
                      <div className="flex items-center gap-2 text-orange-700">
                        <MessageSquare className="w-4 h-4" />
                        <h4 className="font-medium">Pending Requests</h4>
                      </div>
                      {clientSubmissions.map(submission => (
                        <div 
                          key={submission.id}
                          className="bg-white p-3 rounded-md border border-orange-100 space-y-2 cursor-pointer hover:bg-orange-50 transition-colors"
                          onClick={() => setSelectedSubmission(submission)}
                        >
                          <div className="flex justify-between items-start">
                            <div>
                              <p className="font-medium text-gray-900">{submission.dealershipName}</p>
                              <p className="text-sm text-gray-500">Stock #: {submission.stockNumbers}</p>
                            </div>
                          </div>
                          <p className="text-sm text-gray-600 line-clamp-2">{submission.adDescription}</p>
                          <div className="flex justify-between items-center text-xs text-gray-500">
                            <span>{submission.dealershipWebsite || 'No website provided'}</span>
                            <span>{formatDate(submission.timestamp)}</span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            );
          })
        )}
      </div>

      {selectedSubmission && (
        <SubmissionModal
          submission={selectedSubmission}
          onClose={() => setSelectedSubmission(null)}
          onDelete={onDeleteSubmission}
        />
      )}

      {showPendingRequests && (
        <PendingRequestsModal
          submissions={submissions}
          onClose={() => setShowPendingRequests(false)}
          onDelete={onDeleteSubmission}
          onViewDetails={(submission) => {
            setSelectedSubmission(submission);
            setShowPendingRequests(false);
          }}
        />
      )}
    </div>
  );
}