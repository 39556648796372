import { useState, useEffect } from 'react';
import { collection, onSnapshot, query, orderBy, QuerySnapshot, DocumentData, Timestamp } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Client, Submission } from '../types';

export function useClients() {
  const [clients, setClients] = useState<Client[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const q = query(collection(db, 'clients'), orderBy('createdAt', 'desc'));
    
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const clientsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate?.() || null,
        lastSubmission: doc.data().lastSubmission?.toDate?.() || null
      })) as Client[];
      
      setClients(clientsData);
      setLoading(false);
    }, (error) => {
      console.error('Error fetching clients:', error);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return { clients, loading };
}

export function useSubmissions() {
  const [submissions, setSubmissions] = useState<Submission[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const q = query(collection(db, 'submissions'), orderBy('timestamp', 'desc'));
    
    const unsubscribe = onSnapshot(q, (snapshot: QuerySnapshot<DocumentData>) => {
      const submissionsData = snapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          clientId: data.clientId || '',
          clientName: data.clientName || 'Unknown',
          clientPhone: data.clientPhone || 'No Phone',
          dealershipName: data.dealershipName || '',
          dealershipWebsite: data.dealershipWebsite || '',
          stockNumbers: data.stockNumbers || '',
          adDescription: data.adDescription || '',
          timestamp: data.timestamp instanceof Timestamp ? data.timestamp.toDate() : null,
          status: data.status || 'pending'
        } as Submission;
      });
      
      setSubmissions(submissionsData);
      setLoading(false);
    }, (error) => {
      console.error('Error fetching submissions:', error);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return { submissions, loading };
}