import { initializeApp } from 'firebase/app';
import { getFirestore, enableIndexedDbPersistence } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyA-Mr_-GB8TUoC3kkSzhRNM1U4z8PIJHAU",
  authDomain: "ai-video-request-v2.firebaseapp.com",
  projectId: "ai-video-request-v2",
  storageBucket: "ai-video-request-v2.firebasestorage.app",
  messagingSenderId: "625080578969",
  appId: "1:625080578969:web:59478de580a87046571908"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const functions = getFunctions(app);

// Enable offline persistence
enableIndexedDbPersistence(db)
  .catch((err) => {
    if (err.code === 'failed-precondition') {
      console.warn('Multiple tabs open, persistence can only be enabled in one tab at a time.');
    } else if (err.code === 'unimplemented') {
      console.warn('The current browser does not support persistence.');
    }
  });

export { db, functions };