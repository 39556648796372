import { 
  collection, 
  addDoc, 
  updateDoc, 
  deleteDoc, 
  doc, 
  getDocs,
  query,
  where,
  serverTimestamp,
  increment,
  getDoc
} from 'firebase/firestore';
import { db } from './firebase';
import { Client, SubmissionData } from '../types';

// Collections
const CLIENTS_COLLECTION = 'clients';
const SUBMISSIONS_COLLECTION = 'submissions';

// Client Operations
export async function addClient(client: Omit<Client, 'id'>) {
  const docRef = await addDoc(collection(db, CLIENTS_COLLECTION), {
    ...client,
    createdAt: serverTimestamp()
  });
  return docRef.id;
}

export async function updateClientCredits(clientId: string, credits: number) {
  const clientRef = doc(db, CLIENTS_COLLECTION, clientId);
  await updateDoc(clientRef, { credits });
}

export async function deleteClient(clientId: string) {
  // Delete client's submissions first
  const submissionsQuery = query(
    collection(db, SUBMISSIONS_COLLECTION),
    where('clientId', '==', clientId)
  );
  const submissionsSnapshot = await getDocs(submissionsQuery);
  const deleteSubmissions = submissionsSnapshot.docs.map(doc => deleteDoc(doc.ref));
  await Promise.all(deleteSubmissions);

  // Then delete the client
  await deleteDoc(doc(db, CLIENTS_COLLECTION, clientId));
}

// Submission Operations
export async function addSubmission(clientId: string, data: SubmissionData) {
  try {
    // Get client data first
    const clientRef = doc(db, CLIENTS_COLLECTION, clientId);
    const clientSnap = await getDoc(clientRef);
    
    if (!clientSnap.exists()) {
      throw new Error('Client not found');
    }

    const clientData = clientSnap.data();

    // Create submission with client info
    const submissionData = {
      ...data,
      clientId,
      clientName: clientData.name,
      clientPhone: clientData.phone,
      timestamp: serverTimestamp(),
      status: 'pending'
    };

    // Add submission
    const submissionRef = await addDoc(collection(db, SUBMISSIONS_COLLECTION), submissionData);

    // Update client's credits and last submission timestamp
    await updateDoc(clientRef, {
      credits: increment(-1),
      lastSubmission: serverTimestamp()
    });

    return submissionRef.id;
  } catch (error) {
    console.error('Error adding submission:', error);
    throw error;
  }
}

export async function deleteSubmission(submissionId: string) {
  await deleteDoc(doc(db, SUBMISSIONS_COLLECTION, submissionId));
}

export { db };